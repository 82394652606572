<template>
  <div class="user-messages">
    <chat-user />
  </div>
</template>

<script>
import ChatUser from '@/views/shared/chat-full-screen/ChatUser.vue'

export default {
  components: {
    ChatUser,
  },
}
</script>
