<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div
    class="chat-full-screen"
    style="height: inherit"
  >
    <div
      class="body-content-overlay"
      :class="{ show: shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          {{ $t('Start Conversation') }}
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center w-100">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>
              <b-avatar
                size="36"
                :src="$helpers.imageHelper(activeChatPayload.to_user_picture ? activeChatPayload.to_user_picture : activeChatPayload.picture)"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChatPayload)"
              />
              <div class="d-flex align-items-center justify-content-between w-100">
                <h6
                  v-if="activeChatPayload.customer_name"
                  class="mb-0"
                >
                  {{ activeChatPayload.customer_name }}
                </h6>
                <h6
                  v-if="activeChatPayload.to_user_name"
                  class="mb-0"
                >
                  {{ activeChatPayload.to_user_name }}
                </h6>
                <h6
                  v-if="activeChatPayload.first_name"
                  class="mb-0"
                >
                  {{ activeChatPayload.first_name }} {{ activeChatPayload.last_name }}
                </h6>
                <p
                  v-if="activeChatPayload.order_number"
                  class="mb-0 user-post text-capitalize"
                >
                  {{ $t('Order Number:') }} {{ activeChatPayload.order_number }}
                </p>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.picture"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <input
            id="chooseFileToUpload"
            type="file"
            class="d-none"
            @change="uploadImage"
          >
          <feather-icon
            size="30"
            icon="ImageIcon"
            class="upload-image-icon mr-1 cursor-pointer"
            @click="chooseImage"
          />
          <b-button
            variant="primary"
            type="submit"
          >
            {{ $t('Send') }}
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat || {}"
      :active-contact="activeChatPayload"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :is-user="true"
        :contacts="contacts"
        :active-chat-contact-id="activeChatPayload ? activeChatPayload.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
        @reload-contacts="reloadContacts"
      />
    </portal>
  </div>
</template>

<script>
import eventBus from '@/libs/event-bus'
import store from '@/store'
import moment from 'moment'
import { ref, nextTick } from '@vue/composition-api'
import { BAvatar, BForm, BInputGroup, BFormInput, BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { showErrorNotification, useResponsiveAppLeftSidebarVisibility, showDangerNotification, showLoader, hideLoader } from '@core/comp-functions/ui/app'
import { useUserUi } from '@/views/users/useUser'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'

export default {
  components: {
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    VuePerfectScrollbar,
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  data() {
    return {
      chatInputMessage: '',
      chatsContacts: [],
      contacts: [],
    }
  },
  mounted() {
    window.chatRef = this
    this.reloadContacts()
    eventBus.$on('reload-contact-user', data => {
      // eslint-disable-next-line eqeqeq
      if (data && data.id == window.SuperApp.getters.userInfo().id) {
        // eslint-disable-next-line eqeqeq
        if (!window.chatRef.chatsContacts.find(x => x.to_user_id == data.id)) {
          window.chatRef.reloadContacts()
        }
      }
    })
    eventBus.$on('reload-user-new-message', data => {
      // eslint-disable-next-line eqeqeq
      if (data && data.id == window.SuperApp.getters.userInfo().id) {
        // eslint-disable-next-line eqeqeq
        if (window.chatRef.activeChatPayload && (window.chatRef.activeChatPayload.from_user_id == data.id || window.chatRef.activeChatPayload.to_user_id == data.id)) {
          window.chatRef.openChatOfContact(window.chatRef.activeChatPayload, false)
        }
      }
    })
  },
  destroyed() {
    eventBus.$off('reload-contact-user')
    eventBus.$off('reload-user-new-message')
  },
  methods: {
    uploadImage() {
      const input = document.getElementById('chooseFileToUpload')
      if (input) {
        const { files } = input
        if (files && files[0]) {
          const blobUrl = URL.createObjectURL(files[0])
          this.sendMessage(files[0], blobUrl)
        }
      }
    },
    chooseImage() {
      const file = document.getElementById('chooseFileToUpload')
      if (file) {
        file.click()
      }
    },
    resolveAvatarBadgeVariant(user) {
      // eslint-disable-next-line no-prototype-builtins
      if (user.hasOwnProperty('online')) {
        if (user.online === 1) return 'success'
        return 'warning'
      }
      if (user.to_user_online === 1) return 'success'
      return 'warning'
    },
    addChatsMessage(formData) {
      return new Promise(resolve => {
        showLoader()
        const { addUserChat } = useUserUi()

        addUserChat(formData)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              if (this.activeChatPayload && !this.activeChatPayload.to_user_id) {
                this.reloadContacts()
              } else {
              }
              hideLoader()
            } else {
              showDangerNotification(this, data.message)
            }
            resolve(true)
          })
          .catch(error => {
            resolve(true)
            showErrorNotification(this, error)
          })
      })
    },
    reloadContacts() {
      const { getUserChatOverview } = useUserUi()
      showLoader()
      getUserChatOverview()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.chatsContacts = data.responseData
            this.contacts = []
            hideLoader()
          } else {
            showDangerNotification(window.chatRef, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(window.chatRef, error)
        })
    },
  },
  setup() {
    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref(null)
    const activeChatPayload = ref({})
    const openChatOfContact = (payload, loaderShow = true) => {
      window.chatRef.chatInputMessage = ''
      const { getUserChat } = useUserUi()
      if (loaderShow) {
        showLoader()
      }
      let userIdToSend = payload.from_user_id ? payload.from_user_id : payload.id
      if (userIdToSend === window.SuperApp.getters.userInfo().id) {
        userIdToSend = payload.to_user_id
      }
      const chatData = [`/user?to_user_id=${userIdToSend}`]
      getUserChat(chatData.join('&'))
        .then(({ data }) => {
          if (data.statusCode === '200') {
            activeChatPayload.value = payload
            const userMessages = Array.isArray(data.responseData) ? data.responseData[0] : data.responseData
            if (userMessages) {
              userMessages.data = data.responseData.data.sort((a, b) => new Date(moment(a.datetime, 'DD-MM-YYYY')) - new Date(moment(b.datetime, 'DD-MM-YYYY')))
              userMessages.data = userMessages.data.filter(x => x.from_user_id !== x.to_user_id)
              let msgData = []
              userMessages.data.forEach(item => {
                try {
                  if (item && item.message) {
                    msgData.push({
                      from: {
                        id: item.from_user_id,
                        avatar: '',
                      },
                      message: item.message,
                      picture: item.picture,
                      datetime: item.datetime,
                    })
                  }
                } catch (error) {
                  console.log(error)
                }
              })
              msgData = msgData.filter(x => x.message || x.picture)
              activeChat.value = msgData
            } else {
              activeChat.value = []
            }
            hideLoader()
            // Scroll to bottom
            nextTick(() => {
              scrollToBottomInChatLog()
            })
          } else {
            showDangerNotification(window.chatRef, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(window.chatRef, error)
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const sendMessage = async (imageUploaded = null, baseImage = null) => {
      if (!window.chatRef.chatInputMessage) {
        if (!baseImage) return
      }
      const formData = new FormData()
      let userIdToSend = activeChatPayload.value.to_user_id ? activeChatPayload.value.to_user_id : activeChatPayload.value.id
      if (userIdToSend === window.SuperApp.getters.userInfo().id) {
        userIdToSend = activeChatPayload.value.from_user_id
      }
      formData.append('to_user_id', userIdToSend)
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      formData.append('from_user_id', window.SuperApp.getters.userInfo().id)
      if (imageUploaded && baseImage) {
        formData.append('picture', imageUploaded)
        formData.append('message', 'Sent an image')
      }
      if (window.chatRef.chatInputMessage) {
        formData.append('message', window.chatRef.chatInputMessage)
      }
      showLoader()
      console.time()
      const payload = {
        to_user_id: activeChatPayload.value.to_user_id,
        salt_key: window.SuperApp.getters.saltKey(),
        from_user_id: window.SuperApp.getters.userInfo().id,
        picture: window.chatRef.chatInputMessage.picture,
        message: window.chatRef.chatInputMessage,
        datetime: new Date(),
      }
      if (!activeChat.value) {
        activeChat.value = []
      }
      activeChat.value.push({
        from: {
          id: payload.from_user_id,
          avatar: '',
        },
        message: payload.message,
        picture: baseImage,
        datetime: new Date(),
      })
      setTimeout(() => {
      }, 0)
      window.chatRef.chatInputMessage = ''
      await window.chatRef.addChatsMessage(formData)
      console.timeEnd()
      hideLoader()
      nextTick(() => {
        scrollToBottomInChatLog()
      })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      profileUserData.value = window.SuperApp.getters.userInfo()
      shallShowUserProfileSidebar.value = true
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Single Chat
      refChatLogPS,
      activeChat,
      activeChatPayload,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';

.chat-full-screen {
  .chat-app-window {
    height: 75vh;
  }
}
</style>
